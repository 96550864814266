<template>
  <Layout
    :title="
      uploaded ? $t('waterMark.addTitleMessage') : $t('waterMark.addTitle')
    "
  >
    <div :class="prefixCls">
      <!--   无声水印内容   -->
      <div :class="prefixCls + '__body'">
        <!--   添加文件   -->
        <HaiUpload @onChange="onChangeFile" v-show="!uploaded"></HaiUpload>
        <div :class="prefixCls + '__content'" v-show="uploaded">
          <input
            type="text"
            v-model="message"
            :class="prefixCls + '__input'"
            :placeholder="$t('waterMark.placeholder')"
          />
          <p :class="prefixCls + '__tip'">{{ $t('waterMark.format') }}</p>
        </div>
      </div>
      <div :class="prefixCls + '__footer'" v-if="uploaded">
        <HaiButton @click="onClickSubmit">{{ $t('waterMark.add') }}</HaiButton>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import HaiButton from '@/components/HaiButton'
import HaiUpload from '@/components/HaiUpload'

import { GetWatermark, GetWatermarkStatus } from '../api/create'
const prefixCls = 'watermark-add-page'
export default {
  name: 'Copyright',
  components: {
    Layout,
    HaiButton,
    HaiUpload
  },
  data() {
    return {
      prefixCls,
      uploaded: false,
      file: null,
      message: '',
      currentRes: 0
    }
  },
  methods: {
    onChangeFile(file) {
      this.file = file
      this.uploaded = true
    },
    async onClickSubmit() {
      if (!this.file) {
        this.$message({
          text: this.$t('waterMark.fileCheck')
        })
        return
      }
      if (!this.message) {
        this.$message({
          text: this.$t('waterMark.messageCheck')
        })
        return
      }
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('message', this.message)
      this.currentRes = await this.$Loading.startTo(0, 20, this.$t('wait'))
      try {
        const response = await GetWatermark(formData)
        if (response.code === 200) {
          await this.getFetchResult(response.data.taskId)
        } else {
          this.toNextPage()
        }
      } catch (e) {
        this.toNextPage()
      }
    },

    async getFetchResult(taskId) {
      const response = await GetWatermarkStatus({ taskId })
      if (response.code >= 300 && response.code <= 304) {
        if (this.currentRes === response.data.progress) {
          setTimeout(() => {
            this.getFetchResult(taskId)
          }, 2000)
        } else {
          if (this.currentRes < response.data.progress) {
            this.currentRes = await this.$Loading.startTo(
              this.currentRes,
              response.data.progress,
              response.data.text[this.$i18n.locale]
            )
            await this.getFetchResult(taskId)
          } else {
            setTimeout(() => {
              this.getFetchResult(taskId)
            }, 2000)
          }
        }
      } else {
        this.toNextPage(response.data)
      }
    },
    toNextPage(data) {
      window.sessionStorage.setItem('watermark_result', JSON.stringify(data))
      this.$router.push({ name: 'watermarkResult' })
      this.$Loading.finish()
    }
  },
  watch: {
    message(val, oldVal) {
      if (val && (!/^[A-Za-z0-9]+$/.test(val) || val.length > 9)) {
        this.message = oldVal
      }
    }
  }
}
</script>

<style lang="scss">
$prefixCls: 'watermark-add-page';
.#{$prefixCls} {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &__body {
    width: 440px;
    padding: 0 20px;
    margin-top: 180px;
  }
  &__content {
    display: inline-block;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px #9496a1 dashed;
    position: relative;
    box-sizing: border-box;
  }
  &__input {
    text-align: center;
    line-height: 50px;
    font-size: 30px;
    outline: none;
    border: none;
    background: transparent;
    color: #fff;
    &::placeholder {
      color: rgba(255, 255, 255, 0.65);
    }
  }
  &__tip {
    font-size: 16px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.45);
    position: absolute;
    left: 50%;
    bottom: -50px;
    transform: translateX(-50%);
    display: block;
    white-space: nowrap;
  }
  &__footer {
    margin-bottom: 90px;
    .hai-button {
      width: 260px;
      background-image: url('../assets/images/but-long@2x.png');
    }
  }
}
</style>
